import React, {useState, useEffect} from 'react';
import { useSearchParams } from "react-router-dom"
import { MDBCol, MDBRow, MDBInput, MDBCheckbox, MDBBtn } from 'mdb-react-ui-kit';
import base64 from 'react-native-base64';
import axios from 'axios';

//const URL = 'http://merchant.olr-dev.orange.com.lr/api'
const URL = process.env.REACT_APP_API_URL

const Merchant = () =>{

  let [searchParams, setSearchParams] = useSearchParams()
  const code = searchParams.get("code")
  const [isSubmitted, submittedData] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [selected, setSelected] = useState('');



  const [merchant, setMerchant] = useState([]);
  const [resData, setResData] = useState([]);
  const [data, setData] = useState({
    msisdn: '',
    amount: '',
    currency: '',
  })
  const [message, setMessage] = useState("")
  const [notFoundMessage, setNotFoundMessage] = useState("")

  useEffect(() => {

    // http://192.168.1.105:3000/merchant?code=999999

    const fetchData = async () => {

    axios.post(`${URL}/merchant`, {
      code: code
    }, {
      headers: { 'Content-Type': 'application/json' }
    }).then((response) => {
      console.log(response.data.merchant)

      if(response.data.merchant.exec_msg === "Merchant not found") {
        setNotFoundMessage("Merchant not found. Please check the Merchant Code and try again.")
      }
      setMerchant(response.data.merchant)
  
    }).catch((err) => {
        console.log(err.response)
    })
    };
    fetchData()

  }, []);


  const handleSubmit = (event) => {

    setButtonClicked(true)

    console.log(`
      MSISDN: ${data.msisdn}
      amount: ${data.amount}
      currency: ${selected}
      merchantCode: ${code}
    `);

    axios.post(`${URL}/pay`, {
      msisdn: data.msisdn,  
      currency: selected, 
      recipient: merchant.MSISDN,  
      amount: data.amount
    }, {
      headers: { 'Content-Type': 'application/json' }
    }).then((response) => {
      console.log(response.data)
      if(response.data.merchant.resultset.ExecTxt === "Sorry you have insufficient funds.") {
        setMessage("Sorry, you do not have sufficient funds to perform the transaction.")
      }
      
      submittedData(true)
  
    }).catch((err) => {
        console.log(err)
    })
    
    event.preventDefault();
  }

  console.log(message)

  const handleValueChanges = e => { 
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
};

const handleChange = event => {
  console.log(event.target.value);
  setSelected(event.target.value);
};

console.log(buttonClicked)


if (isSubmitted) {
  return (
    <MDBRow className='d-flex justify-content-center'>
      <MDBCol md='6' className='mt-5'>
        <div style={{width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
        <div className="text-center mb-3">
            <p>Pay with Orange Money</p>
            
              <img src={require('../OM.png')} alt="" width="70" height="70" className="d-inline-block align-text-top"/>
          </div>
          {
            message ? <p style={{color: 'red'}} className="text-center">{message}</p>
            : <p className="text-center">An SMS was sent to <strong>{data.msisdn}</strong> with instruction to complete the transaction</p>
          }
        </div>
      </MDBCol>
    </MDBRow>
  );
}

// console.log(resData)


  return (
    <MDBRow className='d-flex justify-content-center'>
      <MDBCol md='6' className='mt-5'>
        <div style={{width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
        <form
          onSubmit={handleSubmit}
        >
          <div className="text-center mb-3">
            <p>Pay with Orange Money</p>
            
              <img style={{marginRight: '20%'}} src={require('../OM.png')} alt="" width="70" height="70" className="d-inline-block align-text-top"/>
              <img src={require('../naresh.png')} alt="" width="70" height="70" className="d-inline-block align-text-top"/>
          </div>

          {/* <div>{merchant.Code}</div>
          <div>{merchant.Name}</div> */}

          { 
            notFoundMessage ? <p style={{color: 'red'}} className="text-center">{notFoundMessage}</p>
            : <p className="text-center">Complete the form to proceed with payment to <strong>{merchant.Name}</strong></p>
          }

          <MDBInput 
            className='mb-4' 
            type='number' 
            id='form1Example1' 
            label='Merchant Code' 
            value={code} 
            disabled
            />

          <MDBInput 
            className='mb-4' 
            type='number' 
            id='form1Example1' 
            label='Enter your phone number' 
            name='msisdn'
            onChange={handleValueChanges}
            value={data.msisdn}
            disabled={notFoundMessage}
            />

          <MDBInput 
            className='mb-4' 
            type='number' 
            id='form1Example1' 
            label='Enter amount' 
            name='amount'
            onChange={handleValueChanges}
            value={data.amount}
            disabled={notFoundMessage}
            />

          <div className="mb-4">
          <div className="form-group mt-3">
                  <label className="form-label" >Currency</label>
                  <select 
                    className="form-select" 
                    aria-label="Default select example"
                    onChange={handleChange}
                    name='currency'
                    value={selected}
                    disabled={notFoundMessage}
                    >
                  <option value="">
                    Select Currency
                  </option>
                  <option value="LRD">LRD</option>
                  <option value="USD">USD</option>
                  </select>
                </div>
          </div>
          
          <button 
          type="submit" 
          className="btn btn-primary btn-block" 
          style={{width: '90%'}}
          disabled={data.msisdn.length === 0 || data.amount.length === 0 || selected.length===0 || buttonClicked}
          >Pay</button>
      </form>
      <p className="text-center">You can download the Orange Money Africa app here:
          <span></span> <a href='https://play.google.com/store/apps/details?id=com.orange.orangemoneyafrique'>Android</a>
          <span></span> <a href='https://apps.apple.com/us/app/orange-money-africa/id1313536959'>iPhone</a>.
          </p>
        </div>
      </MDBCol>
    </MDBRow>
  );
}

export default Merchant
