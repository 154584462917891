import { BrowserRouter as Router } from "react-router-dom";

import logo from './logo.svg';

import { Routes, Route } from "react-router-dom"
import Merchant from "./components/Merchant"
import Home from "./components/Home";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="merchant" element={<Merchant />} />
      </Routes>
    </div>
  )
}

export default App