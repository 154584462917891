import React, { useState, useEffect } from "react";
import { MDBCol, MDBRow, MDBInput, MDBCheckbox, MDBBtn } from 'mdb-react-ui-kit';
import { Link, useNavigate } from "react-router-dom"
import Merchants from "../Merchant";


const Home = () => {

  return (
    <MDBRow className='d-flex justify-content-center'>
      <MDBCol md='6' className='mt-5'>
        <div style={{width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
        <div className="text-center mb-3">
            <p>Pay with Orange Money</p>
            
              <img src={require('../OM.png')} alt="" width="70" height="70" className="d-inline-block align-text-top"/>
          </div>

          <p className="text-center">Scan the QR Code to proceed</p>
          <p> Deploy with GitHub Action. Tag: prod-v1.0.3</p>
        </div>
      </MDBCol>
    </MDBRow>
  );
};

export default Home;
